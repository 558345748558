import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  PopoverBody,
  Popover,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";
import Select from "react-select";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";

const EditPartner = (props) => {
  const { toggle, partnerId } = props;
  const user = useUser();

  const initialValues = {
    fullName: "",
    organization: "",
    password: "",
    threshold: 20,
    usedBonuses: 0,
  };

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [activeYear, setActiveYear] = useState(null);
  const [calculatorValue, setCalculatorValue] = useState(0);

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCalculatorOperation = (operation) => {
    if (operation === "add") {
      setCalculatorValue((prev) => prev + 1);
    } else if (operation === "subtract") {
      setCalculatorValue((prev) => prev - 1);
    }
  };

  const handleApply = (year) => {
    setValues({
      ...values,
      additionalCandidates: {
        ...values.additionalCandidates,
        [year]:
          values.additionalCandidates[year] +
          calculatorValue * values.threshold,
      },
    });
    setPopoverOpen(false);
    setUpdated(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    axios
      .post(`/api/partner/${partnerId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");
          setSuccess(true);
          toast.success(
            partnerId ? "Successfully updated" : "Successfully added"
          );
        } else {
          toast.error("Something went wrong. Is the telegram ID entered?");
        }
      })
      .catch((err) => {
        // console.log(err.response.data);
        setErrorMessage(Object.values(err.response.data.errors)[0]);
        toast.error("Something went wrong. Is the telegram ID entered? (err)");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (partnerId) {
      axios
        .get(`/api/partner/${partnerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          let currentYear = new Date().getFullYear();
          if (!result.data.additionalCandidates[currentYear]) {
            setValues({
              ...result.data,
              additionalCandidates: {
                ...result.data.additionalCandidates,
                [currentYear]: 0,
              },
            });
          } else {
            setValues(result.data);
          }
          setLoading(false);
        })
        .catch((e) => {
          toast.error("something went wrong");
        });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [partnerId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>{partnerId ? "Update Details" : "Add Partner"}</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row className="text-left">
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Full Name</Label>
                        <Input
                          type="text"
                          name="orgStaff"
                          value={values.orgStaff}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Organization</Label>
                        <Input
                          type="text"
                          name="organization"
                          value={values.organization}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Telegram ID</Label>
                        <Input
                          type="number"
                          name="telegramId"
                          value={values.telegramId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Login</Label>
                        <Input
                          type="text"
                          name="login"
                          value={values.login}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          type="text"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label>Phone Number</Label>
                        <Input
                          type="text"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Address</Label>
                        <Input
                          type="text"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Threshold</Label>
                        <Input
                          type="number"
                          name="threshold"
                          value={values.threshold}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Region</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="venue"
                          value={{ label: values.region, value: values.region }}
                          onChange={(e) => {
                            setValues({ ...values, region: e.value });
                            setUpdated(true);
                          }}
                          options={[
                            { label: "Tashkent", value: "Tashkent" },
                            {
                              label: "Tashkent Region",
                              value: "Tashkent Region",
                            },
                            {
                              label: "Online Partners",
                              value: "Online Partners",
                            },
                            { label: "Andijan", value: "Andijan" },
                            { label: "Bukhara", value: "Bukhara" },
                            { label: "Fergana", value: "Fergana" },
                            { label: "Jizzakh", value: "Jizzakh" },
                            { label: "Namangan", value: "Namangan" },
                            { label: "Navoiy", value: "Navoiy" },
                            { label: "Qashqadaryo", value: "Qashqadaryo" },
                            { label: "Samarqand", value: "Samarqand" },
                            { label: "Sirdaryo", value: "Sirdaryo" },
                            { label: "Surxondaryo", value: "Surxondaryo" },
                            { label: "Xorazm", value: "Xorazm" },
                            {
                              label: "Karakalpakstan",
                              value: "Karakalpakstan",
                            },
                          ]}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                        <Label>Booking Url</Label>
                        <Input
                          type="text"
                          name="bookingUrl"
                          value={values.bookingUrl}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Used Bonuses</Label>
                        <Input
                          type="number"
                          name="usedBonuses"
                          value={values.usedBonuses}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>Additional Candidates by Year</Label>
                        <Row>
                          {Object.keys(values.additionalCandidates || {}).map(
                            (year) => (
                              <Col md="2" key={year}>
                                <FormGroup>
                                  <Label>
                                    {year}{" "}
                                    <span
                                      className="clickable"
                                      id={`calc-${year}`}
                                      onClick={() => {
                                        setActiveYear(year);
                                        setCalculatorValue(1);
                                        setPopoverOpen(true);
                                      }}
                                    >
                                      <i className="iconsminds-calculator" />
                                    </span>
                                  </Label>
                                  <Input
                                    type="number"
                                    name={`additionalCandidates.${year}`}
                                    value={values.additionalCandidates[year]}
                                    onChange={(e) => {
                                      setValues({
                                        ...values,
                                        additionalCandidates: {
                                          ...values.additionalCandidates,
                                          [year]: parseInt(e.target.value) || 0,
                                        },
                                      });
                                      setUpdated(true);
                                    }}
                                  />
                                </FormGroup>
                                <Popover
                                  placement="bottom"
                                  isOpen={popoverOpen && activeYear === year}
                                  target={`calc-${year}`}
                                  toggle={() => setPopoverOpen(false)}
                                >
                                  <PopoverBody>
                                    <div className="d-flex flex-column align-items-center">
                                      <div className="d-flex align-items-center mb-2">
                                        <span
                                          className="clickable mr-2"
                                          onClick={() =>
                                            handleCalculatorOperation(
                                              "subtract"
                                            )
                                          }
                                        >
                                          <i className="simple-icon-minus" />
                                        </span>
                                        <Input
                                          type="number"
                                          value={calculatorValue}
                                          onChange={(e) =>
                                            setCalculatorValue(
                                              parseInt(e.target.value) || 0
                                            )
                                          }
                                          style={{ width: "60px" }}
                                          className="text-center"
                                        />
                                        <span
                                          className="clickable ml-2"
                                          onClick={() =>
                                            handleCalculatorOperation("add")
                                          }
                                        >
                                          <i className="simple-icon-plus" />
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between w-100">
                                        <Button
                                          color="light"
                                          size="xs"
                                          onClick={() => setPopoverOpen(false)}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          className="ml-2"
                                          color="primary"
                                          size="xs"
                                          onClick={() =>
                                            handleApply(activeYear)
                                          }
                                        >
                                          Apply
                                        </Button>
                                      </div>
                                    </div>
                                  </PopoverBody>
                                </Popover>
                              </Col>
                            )
                          )}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>Other Details or Comments</Label>
                        <Input
                          type="textarea"
                          name="details"
                          value={values.details}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <div>
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {updated && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `${partnerId ? "Update" : "Add"}`}
                    </Button>
                  )}
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EditPartner;
