import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import { reactSelectChange } from "utils/reactSelectChange";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const JaggedCandidate = (props) => {
  const { toggle, candidateId } = props;
  const user = useUser();

  const initialValues = {};

  const [values, setValues] = useState({});
  const [valuesToUpdate, setValuesToUpdate] = useState({ jaggedSent: true });
  const [results, setResults] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sections, setSections] = useState([]);
  const [examiners, setExaminers] = useState([]);

  const handleGenerate = async () => {
    let tempValues = { ...valuesToUpdate, jaggedDate: new Date() };
    if (sections.includes("Writing")) {
      tempValues.jaggedWritingDetails = {
        requested: true,
        old: results.WScore,
      };
    }
    if (sections.includes("Speaking")) {
      tempValues.jaggedSpeakingDetails = {
        requested: true,
        old: results.SScore,
      };
    }
    setValuesToUpdate(tempValues);
    setValues(tempValues);
    setGenerated(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage("");
    let differenceVal = 0.0;
    if (valuesToUpdate.jaggedDetails.old.score) {
      const oldVal = parseFloat(valuesToUpdate.jaggedDetails.old.score);
      const newVal = parseFloat(valuesToUpdate.jaggedDetails.new.score);
      differenceVal = (newVal - oldVal).toFixed(1);
    }
    let differenceStatus =
      differenceVal > 0.0 ? "higher" : differenceVal < 0.0 ? "lower" : "same";
    await axios.post(`/api/eor-candidate/${candidateId}`, {
      ...valuesToUpdate,
      isJagged: true,
      jaggedDetails: {
        ...valuesToUpdate.jaggedDetails,
        difference: differenceVal.toString(),
        differenceStatus: differenceStatus,
      },
    });
    setSuccess(true);
    toast.success("Successfully added");
  };

  const getExaminers = async () => {
    let result = await axios.get("/api/speaking/examiners");
    let temp = result.data.map((item) => {
      return {
        value: item.staffId,
        label: item.name,
      };
    });
    setExaminers(temp);
  };

  useEffect(() => {
    setLoading(true);
    if (candidateId) {
      axios.get(`/api/candidate/${candidateId}`).then(async (result) => {
        // let resultData = await axios.post("/api/get-result", {
        //   id: result.data.CANDIDATE_NUMBER,
        //   test: result.data.TEST_DATE,
        // });
        // setResults(resultData.data);
        setValues(result.data);
        setValuesToUpdate({
          ...valuesToUpdate,
          jaggedDate: result.data.jaggedDate,
          jaggedDetails: {
            ...result.data.jaggedDetails,
            ...valuesToUpdate.jaggedDetails,
            // old: {
            //   score: resultData.data.SScore,
            //   examinerDetails: {
            //     examinerName: result.data.EXAMINER_NAME,
            //     examinerId: result.data.EXAMINER_NUMBER,
            //   },
            // },
          },
        });
        getExaminers();
        setLoading(false);
      });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [candidateId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Add Jagged</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <p>
                    {values.CANDIDATE_NUMBER} - {values.FAMILY_NAME}{" "}
                    {values.GIVEN_NAME} - {values.TEST_DATE}
                  </p>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Jagged Date</Label>
                        <br />
                        <DatePicker
                          className={"custom-datepicker"}
                          calendarIcon={<i className="simple-icon-calendar" />}
                          clearIcon={<i className="iconsminds-close" />}
                          dayPlaceholder="DD"
                          monthPlaceholder="MM"
                          yearPlaceholder="YYYY"
                          format="dd/MM/y"
                          minDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 100
                              )
                            )
                          }
                          value={
                            valuesToUpdate.jaggedDate
                              ? valuesToUpdate.jaggedDate
                              : null
                          }
                          showLeadingZeros={true}
                          onChange={(v) => {
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              jaggedDate: v,
                              isJagged: true,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Examiner</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          value={
                            valuesToUpdate.jaggedDetails?.old?.examinerDetails
                              ?.examinerId
                              ? {
                                  label:
                                    valuesToUpdate.jaggedDetails?.old
                                      ?.examinerDetails?.examinerName,
                                  value:
                                    valuesToUpdate.jaggedDetails?.old
                                      ?.examinerDetails?.examinerId,
                                }
                              : null
                          }
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              jaggedDetails: {
                                ...valuesToUpdate.jaggedDetails,
                                old: {
                                  ...valuesToUpdate.jaggedDetails.old,
                                  examinerDetails: {
                                    examinerName: e.label,
                                    examinerId: e.value,
                                  },
                                },
                              },
                            })
                          }
                          options={examiners}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Speaking Score</Label>

                        <Input
                          type="text"
                          value={valuesToUpdate.jaggedDetails?.old?.score || ""}
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              jaggedDetails: {
                                ...valuesToUpdate.jaggedDetails,
                                old: {
                                  ...valuesToUpdate.jaggedDetails.old,
                                  score: e.target.value,
                                },
                              },
                            })
                          }
                          defaultValue={results?.SScore}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label>Jagged Examiner</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          value={
                            valuesToUpdate.jaggedDetails?.new?.examinerDetails
                              ?.examinerId
                              ? {
                                  label:
                                    valuesToUpdate.jaggedDetails.new
                                      .examinerDetails.examinerName,
                                  value:
                                    valuesToUpdate.jaggedDetails.new
                                      .examinerDetails.examinerId,
                                }
                              : null
                          }
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              jaggedDetails: {
                                ...valuesToUpdate.jaggedDetails,
                                new: {
                                  ...valuesToUpdate.jaggedDetails.new,
                                  examinerDetails: {
                                    examinerName: e.label,
                                    examinerId: e.value,
                                  },
                                },
                              },
                            })
                          }
                          options={examiners}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Jagged Speaking Score</Label>

                        <Input
                          type="text"
                          value={valuesToUpdate.jaggedDetails?.new?.score}
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              jaggedDetails: {
                                ...valuesToUpdate.jaggedDetails,
                                new: {
                                  ...valuesToUpdate.jaggedDetails.new,
                                  score: e.target.value,
                                },
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Final Speaking Score</Label>

                        <Input
                          type="text"
                          value={valuesToUpdate.jaggedDetails?.finalScore}
                          onChange={(e) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              jaggedDetails: {
                                ...valuesToUpdate.jaggedDetails,
                                finalScore: e.target.value,
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  <Button
                    color={success ? "success" : "primary"}
                    className="top-right-button ml-2"
                    onClick={handleSubmit}
                    disabled={success}
                  >
                    {success ? "Done" : `Add`}
                  </Button>
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default JaggedCandidate;
