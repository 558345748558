import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import Select from "react-select";
import { reactSelectChange } from "utils/reactSelectChange";

const SendSmsModal = (props) => {
  const { toggle, sessionDate, region, venue } = props;
  const user = useUser();

  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [content, setContent] = useState("");
  const [smsOptions, setSmsOptions] = useState([]);

  const [phoneOptions, setPhoneOptions] = useState([]);
  const [toGenerate, setToGenerate] = useState(true);
  const [generatedArrays, setGeneratedArrays] = useState([]);
  const [messagesCount, setMessagesCount] = useState("");
  const [phones, setPhones] = useState([]);
  const [wrongs, setWrongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [provider, setProvider] = useState({ label: "Eskiz", value: "Eskiz" });
  const [allMessages, setAllMessages] = useState([]);

  const handleSendSms = () => {
    setSending(true);
    axios
      .post(
        `/api/speaking/send-sms`,
        { mainTestDate: sessionDate, venue, region, content },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setSending(false);
          toast.success("SMS sent successfully");
          toggle();
          setSuccess(true);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleChooseContent = (detailId) => {
    axios
      .get(`/api/details/info/${detailId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setContent(result?.data?.info);
        setGeneratedArrays([]);
        setToGenerate(true);
      });
  };

  const handleChooseRoom = (room) => {
    setSelectedRoom(room);
    setGeneratedArrays([]);
    setToGenerate(true);
  };

  const getSmsOptions = async () => {
    let sms = await axios.get(`/api/details/info-by-type?type=sms`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    let tempOptions = [];
    sms.data.map((item) => {
      tempOptions.push({
        label: item.title,
        value: item._id,
      });
    });
    setSmsOptions(tempOptions);
    let phone = await axios.get(`/api/details/info-by-type?type=phone`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(phone);
    let tempPhoneOptions = [];
    phone.data[0].infoArray.map((number) => {
      tempPhoneOptions.push({
        label: number,
        value: number,
      });
    });
    setPhoneOptions(tempPhoneOptions);
  };

  const getDistinctRooms = () => {
    axios
      .post(
        `/api/speaking/distinct-rooms`,
        { mainTestDate: sessionDate, venue, region },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        let roomOptions = [];
        result.data.rooms.forEach((item) =>
          roomOptions.push({ label: item, value: item })
        );
        console.log(roomOptions);
        setRooms(roomOptions);
      });
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        provider.value === "OsonTJ"
          ? `/api/sms/generate-tj`
          : `/api/sms/generate`,
        {
          mainTestDate: sessionDate,
          venue,
          region,
          content,
          phones,
          room: selectedRoom?.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setMessagesCount(result.data.messages.length);
          setAllMessages(result.data.messages);
          const limit = 10; // Limit per request

          const chunks = [];

          for (let i = 0; i < result.data.messages.length; i += limit) {
            const chunk = result.data.messages.slice(i, i + limit);
            chunks.push(chunk);
          }
          setGeneratedArrays(chunks);
          setLoading(false);
          setToGenerate(false);
        } else {
          toast.error("Something went wrong");
          setLoading(false);
        }
      });
  };

  const resolveAfterSec = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 1000);
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);

    setSending(true);
    let notSent = [];
    if (provider.value === "Eskiz") {
      for (let i = 0; i < generatedArrays.length; i++) {
        const one = generatedArrays[i];

        await axios
          .post(`/api/sms/batch`, {
            messages: one,
          })
          .then((result) => {
            if (result.status === 200) {
              setSending(false);
              setLoading(false);
              toast.success("SMS sent successfully");
            } else {
              notSent.push(one);
              toast.error("Could not send one batch");
            }
          })
          .catch((err) => {
            notSent.push(one);
            toast.error("Could not send one batch");
          });
        await resolveAfterSec();
      }
    } else if (provider.value === "PlayMobile") {
      await axios
        .post(`/api/playmobile/multi`, {
          messages: allMessages,
        })
        .then((result) => {
          if (result.status === 200) {
            setSending(false);
            setLoading(false);
            toast.success("SMS sent successfully (PlayMobile)");
          } else {
            toast.error("Could not send (PlayMobile)");
          }
        })
        .catch((err) => {
          toast.error("Could not send (PlayMobile)");
        });
    } else if (provider.value === "OsonTJ") {
      await axios
        .post(`/api/osontj/multi`, {
          messages: allMessages,
        })
        .then((result) => {
          if (result.status === 200) {
            setSending(false);
            setLoading(false);
            toast.success("SMS sent successfully (OsonTJ)");
          } else {
            toast.error("Could not send (OsonTJ)");
          }
        })
        .catch((err) => {
          toast.error("Could not send (OsonTJ)");
        });
    }
    if (notSent.length > 0) {
      setGeneratedArrays(notSent);
    }
  };

  useEffect(() => {
    getSmsOptions();
    getDistinctRooms();
  }, []);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Send SMS</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12" className="mb-4">
          <Card className="mb-4">
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <p>
                      Region: {region} &nbsp;&nbsp; | &nbsp;&nbsp; Venue:{" "}
                      {venue}
                    </p>
                  </Col>
                  <Col md="12">
                    <>
                      <FormGroup row>
                        <Label sm="3">Type</Label>
                        <Colxx sm="9">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="venue"
                            onChange={(e) => {
                              handleChooseContent(e.value);
                            }}
                            options={smsOptions}
                          />
                        </Colxx>
                      </FormGroup>
                    </>
                  </Col>
                  <Col md="12">
                    <>
                      <FormGroup row>
                        <Label sm="3">Room</Label>
                        <Colxx sm="9">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="room"
                            onChange={(e) => {
                              handleChooseRoom(e);
                            }}
                            value={selectedRoom}
                            options={rooms}
                          />
                        </Colxx>
                      </FormGroup>
                    </>
                  </Col>
                  <Col md="12">
                    <FormGroup row>
                      <Label sm="3">Message</Label>
                      <Colxx sm="9">
                        <Input
                          type="textarea"
                          name="message"
                          style={{ height: "250px" }}
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                        />
                      </Colxx>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <>
                      <FormGroup row>
                        <Label sm="3">Additional Phones</Label>
                        <Colxx sm="9">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => {
                              setPhones(reactSelectChange(e));
                            }}
                            options={phoneOptions}
                            isMulti
                          />
                        </Colxx>
                      </FormGroup>
                    </>
                  </Col>
                  <Col md="12">
                    <>
                      <FormGroup row>
                        <Label sm="3">Provider</Label>
                        <Colxx sm="9">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => {
                              setProvider(e);
                            }}
                            value={provider}
                            options={[
                              { label: "Eskiz", value: "Eskiz" },
                              { label: "PlayMobile", value: "PlayMobile" },
                              { label: "OsonTJ", value: "OsonTJ" },
                            ]}
                          />
                        </Colxx>
                      </FormGroup>
                    </>
                  </Col>
                </Row>
              </Form>
              <div className="mt-3">
                <Button
                  color="light"
                  className="top-right-button"
                  onClick={toggle}
                >
                  Close
                </Button>
                {/* <Button
                  color={success ? "success" : "primary"}
                  className="top-right-button ml-2"
                  onClick={handleSendSms}
                  disabled={success || sending}
                >
                  {success ? "Done" : `Send`}
                </Button> */}
                {toGenerate ? (
                  <Button
                    color="primary"
                    className="top-right-button ml-2"
                    onClick={handleGenerate}
                    disabled={loading}
                  >
                    Generate
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className="top-right-button ml-2"
                    onClick={handleSend}
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </Button>
                )}
              </div>
              {wrongs.length > 0 &&
                wrongs.map((one, index) => (
                  <div>
                    {messagesCount !== "" && <p>Total: {messagesCount}</p>}
                    <p>
                      {index + 1}: <b>{one.to}</b> <i>{one.text}</i>
                    </p>
                  </div>
                ))}
            </CardBody>
          </Card>
          {generatedArrays.length > 0 && (
            <>
              {generatedArrays.map((one, index) => (
                <Card key={index} className="mb-4">
                  <CardBody>
                    {one.map((inner, innerIndex) => (
                      <div key={innerIndex} className="d-flex flex-row">
                        <div
                          className="pt-1 pb-1 pl-2 d-flex flex-grow-1 min-width-zero"
                          style={{ borderTop: "gray 1px solid" }}
                        >
                          <div className="align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                            <p className="mb-1 text-muted text-small w-20 w-sm-100">
                              {inner.to}
                            </p>
                            <p className="mb-1 text-muted text-small w-80 w-sm-100">
                              {inner.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              ))}
            </>
          )}
        </Colxx>
      </Row>
    </div>
  );
};

export default SendSmsModal;
