import React, { useState } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import Select from "react-select";
import SaveBulkResults from "./saveBulkModal";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import moment from "moment";

const SmsBroadcast = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [bot, setBot] = useState("");
  const [contentOffice, setContentOffice] = useState("");
  const [contentPartner, setContentPartner] = useState("");
  const [candidateNos, setCandidateNos] = useState([]);
  const [wrongs, setWrongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [token, setToken] = useToken();
  const [importModal, setImportModal] = useState(false);
  const [toGenerate, setToGenerate] = useState(true);
  const [generatedArrays, setGeneratedArrays] = useState([]);
  const [messagesCount, setMessagesCount] = useState("");
  const [checkSms, setCheckSms] = useState(false);
  const [phone, setPhone] = useState("998944347291");
  const [country, setCountry] = useState("Uzbekistan");

  let d = new Date();
  d.setDate(d.getDate() - 13);
  const [test, setTest] = useState(d);

  const toggleImportModal = () => {
    setImportModal(!importModal);
  };

  const getResultTexts = () => {
    axios.get(`/api/details/info/647ad4bd837835281699a6b8`).then((result) => {
      setContentOffice(result?.data?.info);
    });

    axios.get(`/api/details/info/647ad631837835281699a6bb`).then((result) => {
      setContentPartner(result?.data?.info);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (bot === "Result") {
      axios
        .post(
          country === "Tajikistan" ? `/api/sms/results-tj` : `/api/sms/results`,
          {
            candidateNos,
            contentOffice,
            contentPartner,
            testDate: moment(test).format("DD/MM/YYYY"),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            setMessagesCount(result.data.messages.length);
            const limit = 10; // Limit per request

            const chunks = [];

            for (let i = 0; i < result.data.messages.length; i += limit) {
              const chunk = result.data.messages.slice(i, i + limit);
              chunks.push(chunk);
            }
            setGeneratedArrays(chunks);
            setLoading(false);
            setToGenerate(false);
          } else {
            toast.error("Something went wrong");
            setLoading(false);
          }
        });
    } else if (bot === "Custom") {
      let tempGens = [];
      for (let i = 0; i < candidateNos.length; i++) {
        const one = candidateNos[i];
        tempGens.push({ to: one.to, text: message });
      }
      setGeneratedArrays(tempGens);
      setLoading(false);
      setToGenerate(false);
    } else {
      axios
        .post(
          `/api/send-sms`,
          { text: message, bot },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            toast.success("The sms has been sent successfully");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setLoading(false);
        });
    }
  };

  const handleCheck = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        `/api/sms/check`,
        { text: message, phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.status[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };

  const resolveAfterSec = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 1000);
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (bot === "Result") {
      setSending(true);
      let notSent = [];
      for (let i = 0; i < generatedArrays.length; i++) {
        const one = generatedArrays[i];
        await axios
          .post(
            country === "Tajikistan" ? `/api/osontj/multi` : `/api/sms/batch`,
            {
              messages: one,
            }
          )
          .then((result) => {
            if (result.status === 200) {
              setSending(false);
              setLoading(false);

              toast.success("SMS sent successfully");
            } else {
              notSent.push(one);
              toast.error("Could not send one batch");
            }
          })
          .catch((err) => {
            notSent.push(one);
            toast.error("Could not send one batch");
          });
        await resolveAfterSec();
      }
      if (notSent.length > 0) {
        setGeneratedArrays(notSent);
      }
    } else if (bot === "Custom") {
      axios
        .post(
          `/api/playmobile/multi`,
          { messages: generatedArrays },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            toast.success("The sms has been sent successfully");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setLoading(false);
        });
    } else {
      axios
        .post(
          `/api/send-sms`,
          { text: message, bot },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            toast.success("The sms has been sent successfully");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setLoading(false);
        });
    }
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Send SMS</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="8"></Col>
                    <Col md="4">
                      <Row>
                        <Label>Check SMS?</Label>&nbsp;
                        <Switch
                          className="custom-switch custom-switch-primary-inverse custom-switch-small"
                          checked={checkSms}
                          size="small"
                          onChange={(value) => setCheckSms(value)}
                        />
                      </Row>
                    </Col>
                    {!checkSms && (
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">Type</Label>
                          <Colxx sm="9">
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="venue"
                              onChange={(e) => {
                                setBot(e.value);
                                if (e.value === "Result") {
                                  getResultTexts();
                                }
                              }}
                              options={[
                                { label: "Result", value: "Result" },
                                { label: "Custom", value: "Custom" },
                              ]}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    )}
                    {bot === "Custom" && (
                      <>
                        <Col md="8">
                          <FormGroup row>
                            <Label sm="3">Upload Excel:</Label>
                            <Colxx sm="9">
                              <Input
                                type="file"
                                accept=".xlsx"
                                onChange={async (e) => {
                                  const files = e.target.files;
                                  if (files) {
                                    const wb = read(
                                      await files[0].arrayBuffer()
                                    );
                                    const data = utils.sheet_to_json(
                                      wb.Sheets[wb.SheetNames[0]]
                                    );
                                    setCandidateNos(data);
                                  }
                                }}
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                        <Col md="4" className="text-left">
                          <p>Numbers: {candidateNos.length}</p>
                        </Col>
                      </>
                    )}
                    <Col md="4" className="text-left">
                      {bot === "Result" && (
                        <Button
                          color="light"
                          className="top-right-button"
                          onClick={toggleImportModal}
                        >
                          Import
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {bot === "Result" ? (
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">Country</Label>
                          <Colxx sm="9">
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="country"
                              value={{ label: country, value: country }}
                              onChange={(e) => {
                                setCountry(e.value);
                              }}
                              options={[
                                { label: "Uzbekistan", value: "Uzbekistan" },
                                { label: "Tajikistan", value: "Tajikistan" },
                              ]}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="3"></Col>
                      <Col md="9" className="text-left">
                        <p>
                          Imported candidates: <b>{candidateNos.length}</b>
                        </p>

                        {candidateNos.length > 0 && (
                          <p>
                            First candidate No: <b>{candidateNos[0]}</b> and the
                            last candidate No:{" "}
                            <b>{candidateNos[candidateNos.length - 1]}</b>
                          </p>
                        )}
                      </Col>
                      <Col md="12">
                        <FormGroup row>
                          <Label sm="3">Message (Office)</Label>
                          <Colxx sm="9">
                            <Input
                              type="textarea"
                              name="message"
                              style={{ height: "250px" }}
                              value={contentOffice}
                              onChange={(e) => setContentOffice(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup row>
                          <Label sm="3">Message (Partner)</Label>
                          <Colxx sm="9">
                            <Input
                              type="textarea"
                              name="message"
                              style={{ height: "250px" }}
                              value={contentPartner}
                              onChange={(e) =>
                                setContentPartner(e.target.value)
                              }
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      {checkSms && (
                        <Col md="8">
                          <FormGroup row>
                            <Label sm="3">Phone</Label>
                            <Colxx sm="9">
                              <Input
                                type="text"
                                name="message"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">Message</Label>
                          <Colxx sm="9">
                            <Input
                              type="textarea"
                              name="message"
                              style={{ height: "250px" }}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </Form>
                <div>
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={() => navigate("/app")}
                  >
                    Cancel
                  </Button>
                  {checkSms ? (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleCheck}
                    >
                      Check
                    </Button>
                  ) : (
                    <>
                      {toGenerate ? (
                        <Button
                          color="primary"
                          className="top-right-button ml-2"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          Generate
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          className="top-right-button ml-2"
                          onClick={handleSend}
                          disabled={
                            loading ||
                            (bot === "Result" && candidateNos.length === 0)
                          }
                        >
                          {loading ? "Sending..." : "Send"}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                {wrongs.length > 0 &&
                  wrongs.map((one, index) => (
                    <div>
                      {messagesCount !== "" && <p>Total: {messagesCount}</p>}
                      <p>
                        {index + 1}: <b>{one.to}</b> <i>{one.text}</i>
                      </p>
                    </div>
                  ))}
              </CardBody>
            </Card>
            {generatedArrays.length > 0 && (
              <>
                {bot === "Custom" && (
                  <Card className="mb-4">
                    <CardBody>
                      {generatedArrays.map((one, index) => (
                        // <div key={index} className="d-flex flex-row">
                        <div
                          key={index}
                          className="pt-1 pb-1 pl-2 flex-grow-1 min-width-zero"
                          style={{ borderTop: "gray 1px solid" }}
                        >
                          <div className="align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                            <p className="mb-1 text-muted text-small w-30 w-sm-100">
                              {one.to}
                            </p>
                            <p className="mb-1 text-muted text-small w-70 w-sm-100">
                              {one.text}
                            </p>
                          </div>
                        </div>
                        // </div>
                      ))}
                    </CardBody>
                  </Card>
                )}
                {bot === "Result" && (
                  <>
                    {generatedArrays.map((one, index) => (
                      <Card key={index} className="mb-4">
                        <CardBody>
                          {one.map((inner, innerIndex) => (
                            <div key={innerIndex} className="d-flex flex-row">
                              <div
                                className="pt-1 pb-1 pl-2 d-flex flex-grow-1 min-width-zero"
                                style={{ borderTop: "gray 1px solid" }}
                              >
                                <div className="align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                  <p className="mb-1 text-muted text-small w-20 w-sm-100">
                                    {inner.to}
                                  </p>
                                  <p className="mb-1 text-muted text-small w-80 w-sm-100">
                                    {inner.text}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </CardBody>
                      </Card>
                    ))}
                  </>
                )}
              </>
            )}
          </Colxx>
        </Row>
        {importModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <SaveBulkResults
                toggle={toggleImportModal}
                setCandidateNos={setCandidateNos}
                test={test}
                setTest={setTest}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default SmsBroadcast;
